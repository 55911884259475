import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const BootstrapModal = styled(Modal)`
  .modal-header {
    height: 100px;
    padding: 0px 20px;
    background: #fff;
    color: #58079e;
    border-bottom: 1px solid #ededed;
    height: 2.75rem !important;
    width: 99.95%;

    .modal-title {
      width: 100%;
      font-size: 20px !important;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-left: 80px;
    padding-right: 80px;
    .botoes-de-filtro {
      button {
        padding: 0 10px;
        height: 24px;
        background: #c6c6c6;
        border: none;

        cursor: pointer;
        & + button {
          margin-left: 2px;
        }
      }
    }
    .aba-ativa {
      background: ${(props) => props.theme.colors.nav} !important;
      color: #fff;
    }
    .modal-acoes {
      height: 70px;
      transition: 0.3s;
      cursor: pointer;

      & + .modal-acoes {
        border-top: 1px solid #ededed;
      }
      & + a {
        border-top: 1px solid #ededed !important;
      }
      h5 {
        font-size: 18px;
        color: #474747;
      }
      small {
        font-size: 13px;
      }
      svg {
        color: ${(props) => props.theme.colors.nav} !important;
      }
    }
    .executando {
      background: #ebebeb;
      transition: 0.3s;

      cursor: default;
      h5 {
        color: #a19d9d !important;
      }
      small {
        color: #a19d9d !important;
      }
      svg {
        color: #9d7dbd !important;
      }
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .nav-tabs {
      .nav-item {
        & + .nav-item {
          display: none;
          visibility: hidden;
        }
      }
    }
    .button-confirm {
      margin-top: 31px;
    }
  }
  .modal-footer {
    color: #58079e;
    button {
      background: ${(props) => props.theme.colors.nav};
      border-color: ${(props) => props.theme.colors.nav};

      &:focus:not(.focus-visible) {
        outline: none !important;
        outline: 0px !important;
        -webkit-appearance: none;
        box-shadow: none !important;
      }
      &:focus-visible {
        outline: 2px solid ${(props) => props.theme.colors.nav} !important;
      }
    }
  }

  .input-qtd {
    outline: none;
    box-sizing: border-box;
    margin: 0;
    display: block;
    width: 100px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    background-color: #fff;
    padding: 8px 6px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 14px;
    text-align: right;
  }
  .is-invalid {
    background-image: none;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    border-bottom: 2px solid #cf1919;
    &:focus {
      box-shadow: none;
    }
    padding: 0.375rem 0.75rem;
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745 !important;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.duplicate {
    background: #007bff;
    width: 300px;
  }
  &.return-search {
    background: #ffc107;
    width: 190px;
  }

  &.limpar {
    background: #ffc107 !important;
  }

  &.cancelar {
    background: #dc3545;
  }

  &.novo {
    background: #007bff;
  }
  &.receipt {
    background: #000000;
  }

  &.delete {
    background: #dc3545;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
