import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { CircularProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlinePlusCircle,
} from 'react-icons/ai';
import { FaArrowsAltH } from 'react-icons/fa';
import { useProduto } from '~/pages/Produto/ProdutoContext';
import { Icon, InputIconContainer } from '~/pages/Produto/styles';
import api from '~/services/api';
import { geraDigitoPLU } from '~/utils/functions';

import { AlertBox, IconBetween } from './styles';

export const Plu: React.FC = () => {
  const [tipo, setTipo] = useState(0); // 0 - PLU alto, // 1 - PLU baixo
  const [isLoading, setIsLoading] = useState(false);

  const {
    formProduto,
    changeFormProduto,
    isUpdate,
    initInicializado,
    isDuplicate,
    setValue,
    getValues,
  } = useProduto();

  const storageKey = 'USER_PREFERENCE_PLU';

  function handleChangeTipoPLU(tipoPlu: number) {
    setTipo(tipoPlu);
    setValue('cod_produto', '');
    changeFormProduto({
      ...formProduto,
      cod_produto: {
        ...formProduto.cod_produto,
        value: '',
      },
    });
    gerarPlu();
    if (!isUpdate) localStorage.setItem(storageKey, tipoPlu.toString());
  }

  async function handleBuscaPLUBaixo() {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const res = await api.get('/produto/plu-baixo');
      const { data, success } = res.data;

      if (success) {
        const { plu } = data;
        const codProduto = geraDigitoPLU(plu);
        setValue('cod_produto', codProduto);
        return changeFormProduto({
          ...formProduto,
          cod_produto: {
            ...formProduto.cod_produto,
            value: codProduto,
            isInvalid: false,
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const gerarPlu = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const isSubmit = getValues('initInicializado');
      if (!isSubmit) {
        const value = localStorage.getItem(storageKey);
        const type = value ? parseInt(value, 10) : tipo;

        const res = await api.get(`/produto/genplu/${type}`);
        const { data, success } = res.data;

        if (success) {
          const { proximo_valor } = data;
          const codProduto = geraDigitoPLU(proximo_valor);
          setValue('cod_produto', codProduto);
          return changeFormProduto({
            ...formProduto,
            cod_produto: {
              ...formProduto.cod_produto,
              value: codProduto,
              isInvalid: false,
            },
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [changeFormProduto, formProduto, isLoading, tipo, setValue]);

  const addAlert = useCallback((): string => {
    const cod_produto = getValues('cod_produto');
    const inistInicializado = getValues('initInicializado');
    if (inistInicializado && !cod_produto) {
      return 'alert-class';
    }
    return '';
  }, []);

  useEffect(() => {
    if (isUpdate) return;

    const userPreferencePluStorage = localStorage.getItem(storageKey);

    setTipo(
      userPreferencePluStorage ? parseInt(userPreferencePluStorage, 10) : 0,
    );
  }, [isUpdate, storageKey]);

  useEffect(() => {
    if (isDuplicate) {
      setValue('cod_produto', '');
      return;
    }

    if (isUpdate) return;

    const isSubmit = getValues('initInicializado');
    if (!isSubmit) {
      const autoGeneratePlu = async () => {
        await gerarPlu();
      };

      autoGeneratePlu();
    }
  }, [isUpdate, isDuplicate]);

  return (
    <>
      <AlertBox>
        <Field name="cod_produto" label="Código PLU">
          {({ fieldProps }) => (
            <InputIconContainer>
              {tipo === 0 && (
                <Icon
                  className="icon-left"
                  style={{
                    backgroundColor: '#28a745',
                    height: 'calc(100% - 6px)',
                    left: 3,
                    top: 3,
                  }}
                  disabled={isUpdate}
                  title="PLU Alto"
                  onClick={() => handleChangeTipoPLU(1)}
                >
                  <AiOutlineArrowUp
                    style={{
                      color: '#fff',
                    }}
                  />
                </Icon>
              )}
              {tipo === 1 && (
                <Icon
                  className="icon-left"
                  style={{
                    backgroundColor: '#ffc107',
                    height: 'calc(100% - 6px)',
                    left: 3,
                    top: 3,
                  }}
                  title="PLU Baixo"
                  onClick={() => handleChangeTipoPLU(0)}
                >
                  <AiOutlineArrowDown
                    style={{
                      color: '#000',
                    }}
                  />
                </Icon>
              )}

              <Textfield
                {...fieldProps}
                value={getValues('cod_produto')}
                className="input input-produto"
                type="text"
                style={{
                  textAlign: 'right',
                  padding: '0px 40px',
                  backgroundColor: 'var(--ds-background-default,#EBECF0)',
                  borderRadius: '3px',
                  fontSize: '14px',
                }}
                isDisabled
              />
              <Icon
                className="icon-right"
                style={{
                  backgroundColor: '#4C9AFF',
                  height: 'calc(100% - 6px)',
                  right: 3,
                  top: 3,
                }}
                disabled={isUpdate}
                onClick={() => {
                  setValue('initInicializado', false);
                  gerarPlu();
                }}
                title="Gerar PLU"
              >
                {isLoading ? (
                  <CircularProgress
                    size={16}
                    style={{
                      color: '#fff',
                    }}
                  />
                ) : (
                  <AiOutlinePlusCircle
                    style={{
                      color: '#fff',
                    }}
                    size={24}
                  />
                )}
              </Icon>
            </InputIconContainer>
          )}
        </Field>
        <div className={addAlert()} />
      </AlertBox>
      {tipo === 1 && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="">Busca o Primeiro PLU Baixo Disponível</Tooltip>
          }
        >
          <IconBetween onClick={handleBuscaPLUBaixo}>
            {isLoading ? (
              <CircularProgress size={14} />
            ) : (
              <FaArrowsAltH fontSize={14} size={14} />
            )}
          </IconBetween>
        </OverlayTrigger>
      )}
    </>
  );
};
