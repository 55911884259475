import * as yup from 'yup';

export const schemModalSped = yup
  .object({
    des_tab_sped: yup
      .object({
        label: yup.string().required(),
        value: yup.mixed().required(),
      })
      .required(),
    cod_tab_sped: yup.object().when('des_tab_sped', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
  })
  .required();
