import styled from 'styled-components';

export const IconBetween = styled.i`
  color: #8850bf;
  background-color: transparent;
  border: 1px solid #8850bf;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 34px;
  margin-top: 30px;

  &:hover {
    background-color: #8850bf;
    color: #fff;
    cursor: pointer;
  }
`;

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.2s;

  .input-produto {
    input {
      &:disabled {
        -webkit-text-fill-color: #000;
      }
    }
  }

  .alert-class {
    width: 100%;
    height: 10px;
    border-bottom: 2px solid #cf1919;
    border-radius: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
